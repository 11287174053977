import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function RegisterPenaltiesPage(props) {
  const [participants, setParticipants] = useState([]);
  const [activeParticipant, setActiveParticipant] = useState(0);
  const penaltyButtonsRef = useRef(null);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  
  useEffect(() => {
    props.raceId && fetchParticipants();
  }, [props.raceId]);

  useEffect(() => {
    if (penaltyButtonsRef.current && activeParticipant) {
      setTimeout(() => {
        penaltyButtonsRef.current.scrollIntoViewIfNeeded();
      }, 0);
    }
  }, [activeParticipant]);
  
  function fetchParticipants() {
    fetch(serverUrl + "/rest/participants/" + props.raceId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setParticipants(data);
      });
  }

  function reportPenalityRounds(participant) {
    setActiveParticipant(participant.bib)
  }

  function sendPenaltyRounds(participant, numberOfRounds) {
    console.log("Send penalty rounds for bib:" + participant.bib + " with rounds: " + numberOfRounds);
    fetch(serverUrl + "/rest/updatePenalties/" + props.raceId + "/" + participant.bib + "/" + numberOfRounds, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      fetchParticipants();
      setActiveParticipant(0);
    });
  }

  return (
    <div className="mx-3">
      <table className="table table-sm table-hover">
        <thead>
          <tr className="row mx-0">
            <th className="col-1 text-end text-responsive pe-3"> Nr</th>
            <th className="col-4 text-responsive"> Namn</th>
            <th className="col-2 text-responsive"> Rundor</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant) => {
            return (
              <tr className="row mx-0" key={participant.bib}>
                <td className="col-1 text-end text-responsive pe-3">
                  <span> {participant.bib} </span>
                </td>
                <td className="col-4 text-responsive">
                  <span>{participant.name}</span>
                </td>
                <td className="col-2 text-responsive">
                  <span>{participant.penalties}</span>
                </td>
                <td className="col-3 text-responsive">
                  <button onClick={() => reportPenalityRounds(participant)}
                          className={`btn ${participant.status ==="dns" ? "btn-secondary" : "color-0"} text-responsive padding-responsive col-12 gap-1`}>Registrera
                  </button>
                </td>
                <td className="col-2">
                  <Link className="btn btn-primary text-responsive padding-responsive col-12 gap-1"
                        to={'/editPenalties/' + participant.bib}>Ändra</Link>
                </td>
                {participant.bib === activeParticipant &&
                    <td>
                      <div ref={penaltyButtonsRef} className="container">
                        <p className="mt-2 mb-1">Straffrundor</p>
                        <div className="row">
                          <button type="button" onClick={() => sendPenaltyRounds(participant, 0)}
                                  className="col btn color-0 m-1">0
                          </button>
                          <button type="button" onClick={() => sendPenaltyRounds(participant, 1)}
                                  className="col btn color-1 m-1">1
                          </button>
                          <button type="button" onClick={() => sendPenaltyRounds(participant, 2)}
                                  className="col btn color-2 m-1">2
                          </button>
                          <button type="button" onClick={() => sendPenaltyRounds(participant, 3)}
                                  className="col btn color-3 m-1">3
                          </button>
                          <button type="button" onClick={() => sendPenaltyRounds(participant, 4)}
                                  className="col btn color-4 m-1">4
                          </button>
                          <button type="button" onClick={() => sendPenaltyRounds(participant, 5)}
                                  className="col btn color-5 m-1">5
                          </button>
                        </div>
                      </div>
                    </td>
                }

              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default RegisterPenaltiesPage;
