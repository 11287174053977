import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function RegisterMissesPage(props) {
  const [participants, setParticipants] = useState([]);
  const [activeParticipant, setActiveParticipant] = useState(0);
  const missesButtonsRef = useRef(null);
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    props.raceId && fetchParticipants();
  }, [props.raceId]);

  useEffect(() => {
    if (missesButtonsRef.current && activeParticipant) {
      setTimeout(() => {
        missesButtonsRef.current.scrollIntoViewIfNeeded();
      }, 0);
    }
  }, [activeParticipant]);
  
  function fetchParticipants() {
    fetch(serverUrl + "/rest/participants/" + props.raceId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setParticipants(data);
      });
  }

  function reportMiss(participant) {
    setActiveParticipant(participant.bib)
  }

  function sendMiss(participant, numberOfMisses, standing) {
    console.log("Sending post for bib:" + participant.bib + " with misses: " + numberOfMisses);
    fetch(serverUrl + "/rest/updateMiss/" + props.raceId + "/" + participant.bib + "/" + numberOfMisses + "/" + standing, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      fetchParticipants();
      setActiveParticipant(0)
    });
  }

  return (
    <div className="mx-3">
      {<table className="table table-sm table-hover">
        <thead>
          <tr className="row mx-0">
            <th className="col-1 text-end text-responsive pe-3"> Nr</th>
            <th className="col-4 text-responsive"> Namn</th>
            <th className="col-1 text-responsive"> L</th>
            <th className="col-1 text-responsive"> S</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant) => {
            return (
              <tr className="row mx-0" key={participant.bib}>
                <td className="col-1 text-end text-responsive pe-3">
                  <span> {participant.bib} </span>
                </td>
                <td className="col-4 text-responsive">
                  <span>{participant.name}</span>
                </td>
                <td className="col-1 text-responsive">
                  <span>{participant.misses}</span>
                </td>
                <td className="col-1 text-responsive">
                  <span>{participant.missesStanding}</span>
                </td>
                <td className="col-3 text-responsive">
                  <button
                      onClick={() => reportMiss(participant)}
                      className={`btn ${participant.status ==="dns" ? "btn-secondary" : "color-0"} text-responsive padding-responsive col-12 gap-1`}
                  >Registrera</button>
                </td>
                <td className="col-2">
                    <Link className="btn btn-primary text-responsive padding-responsive col-12 gap-1" to={'/editMisses/' + participant.bib}>Ändra</Link>
                </td>

                {participant.bib === activeParticipant &&
                    <td>
                      <div ref={missesButtonsRef} className="container">

                        <p className="mt-2 mb-1">Liggande</p>
                        <div className="row">
                          <button type="button" onClick={() => sendMiss(participant, 0, false)}
                                  className="col btn color-0 m-1">0
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 1, false)}
                                  className="col btn color-1 m-1">1
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 2, false)}
                                  className="col btn color-2 m-1">2
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 3, false)}
                                  className="col btn color-3 m-1">3
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 4, false)}
                                  className="col btn color-4 m-1">4
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 5, false)}
                                  className="col btn color-5 m-1">5
                          </button>
                        </div>
                        <p className="mt-3 mb-2">Stående</p>
                        <div className="row mb-4">
                          <button type="button" onClick={() => sendMiss(participant, 0, true)}
                                  className="col btn color-0 m-1">0
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 1, true)}
                                  className="col btn color-1 m-1">1
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 2, true)}
                                  className="col btn color-2 m-1">2
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 3, true)}
                                  className="col btn color-3 m-1">3
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 4, true)}
                                  className="col btn color-4 m-1">4
                          </button>
                          <button type="button" onClick={() => sendMiss(participant, 5, true)}
                                  className="col btn color-5 m-1">5
                          </button>
                        </div>

                      </div>
                    </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>}
    </div>
  );
}

export default RegisterMissesPage;
